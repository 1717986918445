/*
 * action types
 */

export const ADD_MODAL = 'ADD_MODAL';
export const REMOVE_MODAL = 'REMOVE_MODAL';

/*
 * action creators
 */

export const addModalToList = (modal) => ({
  type: ADD_MODAL,
  modal,
});

export const addModal = (modal) => (dispatch) => {
  if (modal?.dataLayer) {
    const dataToPush = {
      ...modal.dataLayer,
      url: document.URL,
      event: modal.dataLayer?.event ? modal.dataLayer?.event : `evt_dialog_${modal?.modalType}`,
    };
    window.dataLayer.push(dataToPush);
  }
  dispatch(addModalToList(modal));
};

export function removeModal() {
  return { type: REMOVE_MODAL };
}
